import { Button } from "@progress/kendo-react-buttons";
import React, { createRef, useState } from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import { Loader } from "@progress/kendo-react-indicators";
import { Label } from "@progress/kendo-react-labels";
import { Input, RadioButton } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { chevronDownIcon } from "@progress/kendo-svg-icons";
import { useNavigate } from "react-router-dom";
import {
  ExternalDropZone,
  Upload,
  UploadFileInfo,
} from "@progress/kendo-react-upload";
import "../css/BioDevice.scss";

export const AddDevice = () => {
  const navigate = useNavigate();
  const uploadRefs = createRef<Upload>();
  const [imageData, setImageData] = useState("");
  const [currentFile, setCurrentFile] = useState<UploadFileInfo>();
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState("1");
  const hint = (
    <span>Click to select files or Drag and drop files here to upload.</span>
  );
  const note = (
    <span>
      Only JPEG, PNG and SVG files are allowed. The maximum upload size is 2 MB
    </span>
  );

  function onSaveRequest(
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any },
    onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void
  ): Promise<{ uid: string }> {
    const currentFile = files[0] as UploadFileInfo;
    setCurrentFile(currentFile);
    const uid = currentFile.uid;
    console.log(currentFile, "121");

    return new Promise<{ uid: string }>((resolve, reject) => {
      if (
        currentFile.validationErrors &&
        currentFile.validationErrors.length > 0
      ) {
        reject({ uid: uid });
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result && typeof reader.result === "string") {
            const base64Result = reader.result.split(",")[1];
            console.log(base64Result, "this< base64");
            setImageData(base64Result);
            resolve({ uid: uid });
          } else {
            reject({ uid: uid });
          }
        };
        reader.onprogress = (data) => {
          onProgress(uid, data);
        };
        reader.onabort = () => {
          reject({ uid: uid });
        };
        reader.onerror = () => {
          reject({ uid: uid });
        };

        reader.readAsDataURL(currentFile.getRawFile!());
      }
    });
  }

  function onRemoveRequest(
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any }
  ): Promise<{ uid: string }> {
    const currentFile = files[0] as UploadFileInfo;
    const uid = currentFile.uid;
    setImageData("");
    return new Promise<{ uid: string }>((resolve) => {
      resolve({ uid: uid });
    });
  }

  const breadCrumbsData = [
    {
      id: "1",
      text: "Biometric Devices",
      route: "",
    },
    {
      id: "2",
      text: "Device Configuration",
      route: "",
    },
    {
      id: "3",
      text: "Add device",
      route: "",
    },
  ];

  return (
    <div>
      {loading ? (
        <div className="loader">
          <Loader size="large" type={"converging-spinner"} />
        </div>
      ) : (
        <div className="overall-view ">
          <div className="overall-view">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Breadcrumbs breadCrumbData={breadCrumbsData} />
            </div>
            <hr className="hr-border" />
          </div>
          <div className="view-margin">
            <div className="common-margin view-margin">
              {/* 1st row */}
              <div className="bio-input-containers">
                <div className="custom-input-views">
                  <Label className="common-label-size">Device ID</Label>
                  <Input
                    className="bio-dropdown"
                    maxLength={25}
                    type="text"
                    disabled
                    value={"001"}
                  />
                </div>
              </div>
              <hr className="hr-border" />
              {/* 2nd row */}
              <div className="custom-input-containers">
                <div className="custom-input-views">
                  <Label>
                    Name of the Device <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    className="bio-dropdown"
                    type="text"
                    maxLength={25}
                    // value={discountName}
                    // onChange={(e) => discountNameSet(e.value)}
                    placeholder="Enter the device name"
                  />
                  {/* {nameError && <Error>{nameError}</Error>} */}
                </div>
                <div className="custom-input-views">
                  <Label>
                    Type of the Device <span style={{ color: "red" }}>*</span>
                  </Label>
                  <DropDownList
                    svgIcon={chevronDownIcon}
                    className="bio-dropdown"
                    // data={discountTypeList}
                    textField="name"
                    dataItemKey="id"
                    // onChange={(e) => handleDropdown(e.target.value)}
                    // value={selectType}
                  />
                  {/* {startError && <Error>{startError}</Error>} */}
                </div>
                <div className="custom-input-views">
                  <Label>
                    Assigned Space <span style={{ color: "red" }}>*</span>
                  </Label>
                  <DropDownList
                    svgIcon={chevronDownIcon}
                    className="bio-dropdown"
                    // data={discountTypeList}
                    textField="name"
                    dataItemKey="id"
                    // onChange={(e) => handleDropdown(e.target.value)}
                    // value={selectType}
                  />
                  {/* {endError && <Error>{endError}</Error>} */}
                </div>
              </div>
              <hr className="hr-border" />
              {/* 3rd row */}
              <div
                style={{
                  display: "flex",
                  width: "50%",
                }}
              >
                <div>
                  <div>
                    Status <span style={{ color: "red" }}>*</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      paddingTop: "10px",
                      gap: "10px",
                      width: "100%",
                    }}
                  >
                    <RadioButton
                      name="group1"
                      value="1"
                      checked={selectedValue === "1"}
                      label="Active"
                      onChange={() => setSelectedValue("1")}
                    />
                    <RadioButton
                      name="group1"
                      value="2"
                      checked={selectedValue === "2"}
                      label="Inactive"
                      onChange={() => setSelectedValue("2")}
                    />
                  </div>
                </div>
              </div>
              <hr className="hr-border" />
              <div>
                <Label>
                  Device Photo <span style={{ color: "red" }}>*</span>
                </Label>
                <ExternalDropZone
                  className="field-space border-file-upload"
                  uploadRef={uploadRefs}
                  customHint={hint}
                  customNote={note}
                />
                <div className="center-view" style={{ paddingTop: "10px" }}>
                  <Upload
                    ref={uploadRefs}
                    restrictions={{
                      maxFileSize: 2000000,

                      allowedExtensions: [".jpeg", ".png", ".svg"],
                    }}
                    batch={false}
                    multiple={false}
                    defaultFiles={[]}
                    withCredentials={false}
                    saveUrl={onSaveRequest}
                    removeUrl={onRemoveRequest}
                  />
                  {/* {errorimageMessage && (
              <div style={{ color: "red" }}>{errorimageMessage}</div>
            )} */}
                </div>
              </div>
              <div
                className="center-view"
                style={{
                  paddingBottom: "50px",
                }}
              >
                <div className="form-row-area">
                  <div className="form-group-area"></div>
                </div>
                <div className="button-group">
                  <Button
                    className="button-view"
                    onClick={() => navigate("/biodevice/deviceconfig")}
                  >
                    ADD
                  </Button>
                  <Button
                    // type="submit"
                    className="common-button-view"
                    onClick={() => navigate("/biodevice/deviceconfig")}
                  >
                    CANCEL
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
