import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import {
  calendarIcon,
  chartAreaRangeIcon,
  chevronDownIcon,
  exportIcon,
  listUnorderedIcon,
  plusIcon,
} from "@progress/kendo-svg-icons";
import {
  commonBaseUrl,
  commonImageUrl,
  commonChartColor,
} from "../../services/CommonUtil";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Label } from "@progress/kendo-react-labels";
import { MyDatePicker } from "../masters/MyDatePicker";
import { DatePicker, today } from "@progress/kendo-react-dateinputs";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Loader } from "@progress/kendo-react-indicators";
import {
  Chart,
  ChartTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
} from "@progress/kendo-react-charts";
import axios from "axios";
import { PDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { State } from "@progress/kendo-data-query";

const initialDataState: State = {
  take: 5,
  skip: 0,
  group: [],
};

export const Revenue = () => {
  const baseUrl = commonBaseUrl();
  const imageUrl = commonImageUrl();
  //
  const [loader, setLoader] = useState(false);
  const [selected, setSelected] = useState("1");

  const [selectspaceType, setselectSpaceType] = useState();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [searchQuery, setSearchQuery] = useState("");

  //
  const [errorDate, setErrorDate] = useState<string | null>(null);
  const [errorstartDate, setErrorstartDate] = useState<string | null>(null);

  const [page, setPage] = useState({
    skip: 0,
    take: 5, // Number of rows per page
  });
  const onPageChange = (event: any) => {
    setPage({
      skip: event.page.skip,
      take: event.page.take,
    });
  };

  //
  const [spaceTypes, setSpaceTypes] = useState([]);

  const breadCrumbsData = [
    {
      id: "1",
      text: "Reports",
      route: "",
    },
    {
      id: "2",
      text: "Revenue Report",
      route: "",
    },
  ];
  //
  const customStyles = `
  .no-wheel input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Hide spinner controls */
    pointer-events: none;
    user-select: none;
  }
  
  .no-wheel input::-webkit-inner-spin-button,
  .no-wheel input::-webkit-calendar-picker-indicator {
    display: none;
  }
  
  .no-wheel input::-moz-inner-spin-button {
    display: none;
  }
  `;

  const onDateChange = (e: any) => {
    // setSelectedDate(e);
    setPage({ skip: 0, take: page.take });
  };
  const onSearchChange = (e: any) => {
    setSearchQuery(e.target.value);
    setPage({ skip: 0, take: page.take });
  };
  const clearDateFilter = () => {
    setSearchQuery("");
    setselectSpaceType(undefined);
    setStartDate(getStartDate());
    setToDate(getEndDate());
    GetMonthApi(`${getStartDate()}`, `${getEndDate()}`);
    setPage({ skip: 0, take: page.take });
  };
  useEffect(() => {
    console.log("dtashdchgascdkhgascdhagvs");
  }, [startDate, toDate]);
  const [catBookingData, setcatBookingData] = useState([]);
  ////
  useEffect(() => {
    getSpaceTypes();
    setLoader(false);
    // getRevenueList();
    // searchFeildApi();
    setStartDate(getStartDate());
    setToDate(getEndDate());
    GetMonthApi(`${getStartDate()}`, `${getEndDate()}`);
  }, []);

  const getStartDate = () => {
    let now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1);
  };

  const getEndDate = () => {
    let now = new Date();
    return new Date(now.getFullYear(), now.getMonth() + 1, 0);
  };

  const GetMonthApi = async (fromDate?: string, toDate?: string) => {
    setLoader(true);
    console.log(formatDate, toDate, "drftgyioutdrfgtyhuiojtrd");
    try {
      const linedarList = await axios.get(
        `${baseUrl}GetDailyLineChart?${
          fromDate !== "" ? `fromDate=${formattedDate(fromDate)}` : ""
        }${toDate !== "" ? `&toDate=${formattedDate(toDate)}` : ""}`
      );
      const pieList = await axios.get(
        `${baseUrl}GetRevenuePieChart?${
          fromDate !== "" ? `fromDate=${formattedDate(fromDate)}` : ""
        }${toDate !== "" ? `&toDate=${formattedDate(toDate)}` : ""}`
      );

      setPieChartValue(pieList.data);
      const DataValue = linedarList.data.map((m: any) => {
        console.log(Date());
        return {
          bookingDate: formattedDate(m.bookingDate),
          totalRevenue: m.totalRevenue,
        };
      });
      setcatBookingData(DataValue);

      const revenueListData = await axios.get(
        `${baseUrl}GetRevenueList${
          fromDate !== undefined ? `?fromDate=${formattedDate(fromDate)}` : ""
        }${toDate !== undefined ? `&toDate=${formattedDate(toDate)}` : ""}`
      );
      const Data = revenueListData.data.map((ds: any) => {
        return {
          spaceName: ds.spaceName,
          bookingDate: formattedDate(ds.bookingDate),
          amount: `₹ ${ds.amount}`,
        };
      });
      const dataWithSerialNumbers = Data.map((item: any, index: any) => ({
        serialNumber: index + 1,
        ...item,
      }));
      setRevenueList(dataWithSerialNumbers);
      setLoader(false);
    } catch (e) {}
  };

  const getSpaceTypes = async () => {
    try {
      const spaceresponce = await axios.get(`${baseUrl}odata/SpaceTypes`);
      const spaceTypes = spaceresponce.data.value;
      setSpaceTypes(spaceTypes);
      console.log(spaceTypes, "sdfghjkolkjhgfdsdfghuytfrdxcvbjhtfvb");
    } catch (e) {}
  };
  const [RevenueList, setRevenueList] = useState([]);
  const [linearChart, setLinearchart] = useState();
  const [piechartValue, setPieChartValue] = useState([]);

  const getRevenueList = async () => {
    let initValue = 1;
    try {
      //list
      const revenueList = await axios.get(`${baseUrl}GetRevenueList`);
      console.log("revenueList", revenueList);
      const Data = revenueList.data.map((ds: any, index: any) => {
        return {
          id: initValue + index,
          spaceName: ds.spaceName,
          bookingDate: formattedDate(ds.bookingDate),
          amount: `₹ ${ds.amount}`,
        };
      });
      const dataWithSerialNumbers = Data.map((item: any, index: any) => ({
        serialNumber: index + 1,
        ...item,
      }));
      setRevenueList(dataWithSerialNumbers);

      //chart 1
      const linedarList = await axios.get(`${baseUrl}GetDailyLineChart`);
      const pieList = await axios.get(`${baseUrl}GetRevenuePieChart`);
      console.log("p", pieList.data, "l", linedarList.data);
      setPieChartValue(pieList.data);
      const DataValue = linedarList.data.map((m: any) => {
        console.log(Date());
        return {
          bookingDate: formattedDate(m.bookingDate),
          totalRevenue: m.totalRevenue,
        };
      });
      setcatBookingData(DataValue);
    } catch (e) {}
  };

  const filteredInvoices = RevenueList.filter((Value: any) => {
    return (
      Value.spaceName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      Value.bookingDate.toLowerCase().includes(searchQuery.toLowerCase()) ||
      Value.amount.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  // Pagination with settings
  const handlePageChange = (pageState: any) => {
    setDataState({ ...dataState, ...pageState });
  };

  const getPageableSettings = () => {
    const length = filteredInvoices.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 1 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 2 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };

  const [dataState, setDataState] = useState<State>(initialDataState);

  // DetailReportViews
  const DetailReport = () => {
    return (
      <div className="overall-view ">
        <div className="overall-view ">
          <div className="overall-view ">
            <div className=" filter-views ">
              <div className="large-custom-input-container">
                <h4>Details Report</h4>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div className="rowset">
                    <Label className="common-label-size"></Label>
                    <Input
                      type="text"
                      className="bio-dropdown"
                      placeholder="Search by Space Type, Date, or Revenue..."
                      value={searchQuery}
                      onChange={onSearchChange}
                      style={{
                        marginBottom: "10px",
                        padding: "8px",
                        width: "300px",
                      }}
                    />
                  </div>

                  <div
                    className="columnsset"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Label className="common-label-size"></Label>
                    <Button
                      onClick={() => {
                        setSearchQuery("");
                        // clearDateFilter();
                      }}
                      className="common-button-view clear-color"
                    >
                      Clear
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="overall-view ">{/* <h4>Details Report</h4> */}</div>
          <ExcelExport ref={_export} fileName="Revenue Report.xlsx" />
          <Grid
            data={filteredInvoices.slice(page.skip, page.skip + page.take)}
            pageable={getPageableSettings()}
            skip={page.skip}
            take={page.take}
            total={filteredInvoices.length}
            onPageChange={onPageChange}
            onDataStateChange={handlePageChange}
            ref={_grid}
          >
            <GridColumn
              field="serialNumber"
              title="S.No"
              width="60px"
              cell={(props) => <td style={{}}>{props.dataIndex + 1}</td>}
            />
            <GridColumn field="spaceName" title="Space Type" />
            <GridColumn field="bookingDate" title="Date" />
            <GridColumn field="amount" title="Revenue Report" />
          </Grid>
        </div>
      </div>
    );
  };
  const labelContent = (props: any) => {
    let formatedNumber = Number(props.dataItem.value).toLocaleString(
      undefined,
      {
        style: "percent",
        minimumFractionDigits: 2,
      }
    );
    return `${props.dataItem.category} years old: ${formatedNumber}`;
  };

  const pdfExportComponent = React.useRef<PDFExport>(null);
  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  const _export = React.useRef<ExcelExport | null>(null);
  const _grid = React.useRef<any>();
  const excelExport = () => {
    console.log("revenu", RevenueList);
    if (_export.current !== null && RevenueList.length !== 0) {
      _export.current.save(RevenueList, _grid.current.columns);
    }
  };

  const summaryReport = () => {
    return (
      <div className="overall-view">
        <PDFExport
          ref={pdfExportComponent}
          paperSize="auto"
          margin={40}
          fileName="Revenue Report"
        >
          <div className="overall-view">
            <div className="overall-view">
              <div>
                <h4>Total Revenue</h4>
                {/* <Chart>
              <ChartTitle text="Daily Revenue" />
              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  title={{ text: "Days" }}
                  categories={linearChart ? linearChart.bookingDate : []}
                />
              </ChartCategoryAxis>
              <ChartSeries>
                <ChartSeriesItem
                  type="line"
                  data={linearChart ? linearChart.totalRevenue : []}                />
              </ChartSeries>
            </Chart> */}
                {catBookingData.length !== 0 ? (
                  <Chart className="report-border" transitions={false}>
                    <ChartSeries>
                      <ChartSeriesItem
                        color={"#abcc66"}
                        type="line"
                        field="totalRevenue"
                        categoryField="bookingDate"
                        data={catBookingData}
                      />
                    </ChartSeries>
                  </Chart>
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    No Revenue data available.
                  </div>
                )}
              </div>

              <hr className="hr-border" />
              <div>
                <h4>Total Revenue Breakdown in percentage</h4>
                {piechartValue.length > 0 ? (
                  <Chart>
                    <ChartTitle text="" />
                    <ChartLegend position="bottom" />
                    <ChartSeries>
                      <ChartSeriesItem
                        color={commonChartColor}
                        type="pie"
                        data={piechartValue}
                        field="percentage"
                        categoryField="spaceName"
                        labels={{
                          visible: true,
                          // content:
                          //   piechartValue !== undefined ? piechartValue : undefined,
                        }}
                      />
                    </ChartSeries>
                  </Chart>
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    No Revenue Breakdown data available.
                  </div>
                )}
              </div>

              <div></div>
            </div>
          </div>
        </PDFExport>
      </div>
    );
  };

  const searchFeildApi = async (
    id?: number,
    fromDate?: string,
    toDate?: string
  ) => {
    setLoader(true);
    console.log(fromDate, toDate, "search revenue");
    try {
      const linedarList = await axios.get(
        `${baseUrl}GetDailyLineChart?${
          fromDate !== "" ? `fromDate=${fromDate}` : ""
        }${toDate !== "" ? `&toDate=${toDate}` : ""}${
          id !== null ? `&spaceId=${id}` : ""
        }`
      );
      const pieList = await axios.get(
        `${baseUrl}GetRevenuePieChart?${
          fromDate !== "" ? `fromDate=${fromDate}` : ""
        }${toDate !== "" ? `&toDate=${toDate}` : ""}${
          id !== null ? `&spaceId=${id}` : ""
        }`
      );

      setPieChartValue(pieList.data);
      const DataValue = linedarList.data.map((m: any) => {
        console.log(Date());
        return {
          bookingDate: formattedDate(m.bookingDate),
          totalRevenue: m.totalRevenue,
        };
      });
      setcatBookingData(DataValue);

      const revenueListData = await axios.get(
        `${baseUrl}GetRevenueList?${
          fromDate !== "" ? `fromDate=${fromDate}` : ""
        }${toDate !== "" ? `&toDate=${toDate}` : ""}${
          id !== null ? `&spaceId=${id}` : ""
        }`
      );
      const Data = revenueListData.data.map((ds: any) => {
        return {
          spaceName: ds.spaceName,
          bookingDate: formattedDate(ds.bookingDate),
          amount: `₹ ${ds.amount}`,
        };
      });
      const dataWithSerialNumbers = Data.map((item: any, index: any) => ({
        serialNumber: index + 1,
        ...item,
      }));
      setRevenueList(dataWithSerialNumbers);
      setLoader(false);
    } catch (e) {}
  };

  return (
    <div className="overall-view">
      <div className="space-burger" style={{}}>
        <Breadcrumbs breadCrumbData={breadCrumbsData} />
        <div>
          {selected === "1" ? (
            <div>
              <Button
                className="bio-device-button-view"
                onClick={exportPDFWithComponent}
              >
                Download Summary
              </Button>
            </div>
          ) : (
            <div>
              <Button
                className="bio-export-button-view"
                svgIcon={exportIcon}
                onClick={excelExport}
              >
                Export To CSV
              </Button>
            </div>
          )}
        </div>
      </div>
      <hr className="hr-border" />
      <div className="form-row-create">
        <h3>Filter</h3>
      </div>
      <div className=" filter-view ">
        <div className="form-row-create">
          <div className="form-group" style={{ padding: "10px" }}>
            <Label>
              Space Types
              <span style={{ color: "red" }}></span>
            </Label>
            <DropDownList
              svgIcon={chevronDownIcon}
              style={{
                backgroundColor: "white",
              }}
              className="book-dropdown-new"
              data={spaceTypes}
              textField="Name"
              value={
                selectspaceType || {
                  Id: 0,
                  Name: "Select",
                }
              }
              onChange={(e) => {
                setselectSpaceType(e.value);
              }}
            />
          </div>

          <div className="form-group" style={{ padding: "10px" }}>
            <Label>
              Start Date <span style={{ color: "red" }}></span>
            </Label>
            <style>{customStyles}</style>
            <div className="no-wheel" style={{ width: "80%" }}>
              <MyDatePicker
                // minDate={new Date()}
                maxDate={new Date()}
                checkInDate={startDate!}
                isDisableCheck={false}
                customFormatter={false}
                setCheckInDate={(e: any) => {
                  console.log(e, "auyfsdkuvahsvdygfy");
                  setStartDate(e);
                }}
              />
            </div>

            {errorstartDate && <p style={{ color: "red" }}>{errorstartDate}</p>}
          </div>
          <div className="form-group" style={{ padding: "10px" }}>
            <Label>
              End Date <span style={{ color: "red" }}></span>
            </Label>
            <style>{customStyles}</style>
            <div className="no-wheel" style={{ width: "80%" }}>
              <MyDatePicker
                minDate={startDate!}
                maxDate={new Date()}
                checkInDate={toDate!}
                isDisableCheck={startDate !== null ? false : true}
                customFormatter={false}
                setCheckInDate={(e: any) => {
                  setToDate(e);
                }}
              />
            </div>

            {errorDate && <p style={{ color: "red" }}>{errorDate}</p>}
          </div>
          <div
            className="form-group"
            style={{
              marginTop: "30px",
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              onClick={() => {
                const startDateValue =
                  startDate !== null ? DateFormater(startDate!) : null;
                const endDateValue =
                  toDate !== null ? DateFormater(toDate!) : null;
                const spaceIdValue =
                  selectspaceType !== undefined ? selectspaceType["Id"] : null;
                if (startDateValue !== null && endDateValue === null) {
                  setErrorDate("select end date");
                } else {
                  setErrorDate("");
                  searchFeildApi(
                    spaceIdValue!,
                    `${startDateValue}`,
                    `${endDateValue}`
                  );
                  console.log("ausgfjna sjyaisbiuagtsud asgfkms fklh");
                }
              }}
              className="common-button-view search-color"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                setErrorDate("");
                clearDateFilter();
              }}
              className="common-button-view clear-color"
            >
              Clear
            </Button>
            <div></div>
          </div>
        </div>
      </div>
      <div style={{ padding: "10px" }}></div>

      <div>
        <div className="button-toggle">
          <ButtonGroup>
            <Button
              selected={selected === "1"}
              onClick={() => {
                // clearDateFilter();
                setSelected("1");
              }}
              className={selected === "1" ? "selected-color" : "unselect-color"}
              svgIcon={chartAreaRangeIcon}
              style={{ borderColor: "#42B677" }}
            >
              SUMMARY REPORT
            </Button>
            <Button
              selected={selected === "2"}
              onClick={() => {
                // clearDateFilter();
                setSelected("2");
              }}
              svgIcon={listUnorderedIcon}
              className={selected === "2" ? "selected-color" : "unselect-color"}
              style={{ borderColor: "#42B677" }}
              // className= {selected === 'calendar' ?"unselect-color" : 'selected-color'}
            >
              DETAIL REPORT
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <div style={{ paddingTop: "50px", paddingBottom: "50px" }}>
        {loader === true ? (
          <div className="loader">
            <Loader size="large" type={"converging-spinner"} />
          </div>
        ) : (
          <div className="overall-view">
            {selected === "1" ? summaryReport() : DetailReport()}
          </div>
        )}
      </div>
      {/* <div style={{height: " 1000000px"}}>

      </div> */}
    </div>
  );
};

const DateFormater = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
const formatDate = (date: any) => {
  if (!date) return "";
  return date.toLocaleDateString("en-CA"); // Formats date to YYYY-MM-DD
};
const formattedDate = (dateString: any) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};
const formattedDateValue = (dateString: any) => {
  const year = new Date(dateString.getFullYear());
  const month = new Date(dateString.getMonth());
  const day = new Date(dateString.getDate());

  return `${day}/${month}/${year}`;
};
